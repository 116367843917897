.header {
    display: flex;
    flex-grow: 1;
    height: 0;
    min-height: 0;
    max-height: 0;
    justify-content: center;
    align-items: center;
    background: $headerBgColor;

    &.izum {
        height: 48px;
        min-height: 48px;
        max-height: 48px;

        @media screen and (max-width: 1000px) {
            justify-content: right;
    
            a {
                display: none;
            }
        }
    }

    a {
        margin-right: 25px;
        font-size: 0.9rem;
        color: rgba(255, 255, 255, 0.75);
        text-decoration: none;

        &:hover {
            color: #fff;
        }
    }

    .lng {
        width: auto;
        min-width: auto;
        color: rgba(255, 255, 255, 0.75);

        &.active {
            color: #fff;
        }
    }
}
