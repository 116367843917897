.loginPage {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #ffffff;

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 0 auto;
        margin-top: 50px;

        .card {
            background: #f5f5f5;

            .title span {
                font-size: 1.1rem;
            }
        }
    }
}
